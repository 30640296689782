<template>
  <div>
    <component :is="currentComponent" />
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import MobileComponent from "./Component/MobileComponent.vue";
import DesktopComponent from "./Component/DesktopComponent.vue";

const currentComponent = ref(null);

const loadComponent = () => {
  currentComponent.value =
    window.innerWidth <= 768 ? MobileComponent : DesktopComponent;
};

onMounted(() => {
  loadComponent();
  window.addEventListener("resize", loadComponent);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", loadComponent);
});
</script>
