<template>
  <div class="container">
    <img class="bg" src="../assets/bg.jpg" />
    <div class="img-container">
      <img
        class="responsive-image"
        style="margin-top: 50px"
        src="../assets/slogan.png"
      />
      <img
        class="responsive-image"
        src="../assets/apk.png"
        style="margin-top: 30px"
        @click="jumpUrl(urlData.apk)"
      />
      <img
        class="responsive-image"
        src="../assets/ipa.png"
        style="margin-top: 18px"
        @click="jumpUrl(urlData.ipa)"
      />
      <img
        class="responsive-image"
        src="../assets/tutorial.gif"
        style="margin-top: 18px"
        @click="showTip = true"
      />
      <img
        class="responsive-image"
        style="margin-top: 18px"
        src="../assets/girl.png"
      />
      <img
        class="responsive-image"
        style="margin-top: 18px"
        src="../assets/tip.png"
      />
    </div>
    <img class="tg" src="../assets/tg.png" @click="jumpUrl(urlData.tg)" />
    <div class="tip-main" v-show="showTip">
      <img class="desc" src="../assets/desc.png" />
      <img class="close" src="../assets/back.png" @click="showTip = false" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

const showTip = ref(false);
const urlData = ref({});

const loadJsonData = async () => {
  const response = await fetch("/url.json");
  urlData.value = await response.json();
};

const jumpUrl = (url) => {
  window.open(url, "_blank");
};

onMounted(() => {
  loadJsonData();
});
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.container {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.responsive-image {
  width: calc(100vw - 60px);
  object-fit: contain; /* 保持图片纵横比 */
}

.bg {
  width: 100vw;
  height: 100%;
  position: absolute;
}

.tg {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 30px;
  top: 30px;
}

.img-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
}

.tip-main {
  width: 100vw;
  height: 100%;
  position: absolute;
}
.close {
  width: 20px;
  height: 20px;
  left: 30px;
  top: 50px;
  position: absolute;
}
.desc {
  max-width: 100vw;
  max-height: 100vh;
  object-fit: contain;
}
</style>
